<template>
  <div class="content">
    <PageHeader :title="'Reclamações'" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="row m-0 col-border-xl">
              <div class="col-md-4 col-lg-4 col-xl-4">
                <div class="card-body">
                  <div class="icon-rounded icon-rounded-primary float-left m-r-20">
                    <i class="fas fa-check MuiSvgIcon-root"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">{{answered}}</h5>
                  <h6 class="text-muted m-t-10">
                    Respondidas
                  </h6>
                  <div class="progress progress-active-sessions mt-4" style="height:7px;">
                    <div class="progress-bar bg-primary" role="progressbar" :style="`width: ${this.answeredPercent}%;`" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <small class="text-muted float-right m-t-5 mb-3 counter append-percent" data-count="0">{{answeredPercent}}</small>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-xl-4">
                <div class="card-body">
                  <div class="icon-rounded icon-rounded-accent float-left m-r-20">
                    <i class="fas fa-times MuiSvgIcon-root"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">{{unAnswered}}</h5>
                  <h6 class="text-muted m-t-10">
                    Não respondidas
                  </h6>
                  <div class="progress progress-add-to-cart mt-4" style="height:7px;">
                    <div class="progress-bar bg-danger" role="progressbar" :style="`width: ${this.unAnsweredPercent}%;`" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <small class="text-muted float-right m-t-5 mb-3 counter append-percent" data-count="0">{{this.unAnsweredPercent}}</small>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-xl-4">
                <div class="card-body">
                  <div class="icon-rounded float-left m-r-20 icon-rounded-success">
                    <i class="fas fa-check MuiSvgIcon-root"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">{{resolved}}</h5>
                  <h6 class="text-muted m-t-10">
                    Resolvido
                  </h6>
                  <div class="progress progress-add-to-cart mt-4" style="height:7px;">
                    <div class="progress-bar bg-success" role="progressbar" :style="`width: ${this.resolvedPercent}%;`" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <small class="text-muted float-right m-t-5 mb-3 counter append-percent" data-count="0">{{this.resolvedPercent}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <form class="row" _lpchecked="1">
                <div class="col-md-4">
                  <label class="sr-only" for="inlineFormInputName2"
                    >{{$t('generic-str.search-by.default')}}</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ID..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      v-model="form.id"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary btn-icon"
                        type="button"
                        @click="fetch(1)"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label class="sr-only" for="status">Status</label>
                  <select
                    class="form-control"
                    id="status"
                    v-model="form.status"
                    @change="fetch(1)"
                  >
                    <option selected value>Status</option>
                    <option value="open">{{$t('generic-str.status.lbl-open')}}</option>
                    <option value="closed">{{$t('generic-str.status.lbl-closed')}}</option>
                    <option value="archived">{{$t('generic-str.status.lbl-filed')}}</option>
                  </select>
                </div>
              </form>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive">
                <table v-if="fetched && tickets.length" class="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>{{$t('generic-str.subject')}}</th>
                      <th>{{$t('generic-str.date')}}</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ticket in tickets" :key="ticket.id">
                      <td>{{ ticket.id }}</td>
                      <td>{{ ticket.complaint_title }}</td>
                      <td>{{ ticket.creation_date | formatDate24 }}</td>
                      <td>
                        <span
                          class="badge badge-success"
                          v-if="ticket.ra_status.name == 'Avaliado Resolvido'"
                          >Resolvido e avaliado</span
                        >
                        <span
                          class="badge badge-warning"
                          v-if="ticket.ra_status.name == 'Avaliado Não Resolvido'"
                          >Avaliado Não Resolvido</span
                        >
                        <span
                          class="badge badge-dark"
                          v-if="ticket.ra_status.name == 'Réplica da empresa'"
                          >Réplica da empresa</span
                        >
                        <span
                          class="badge badge-info"
                          v-if="ticket.ra_status.name == 'Respondido'"
                          >Respondido</span
                        >
                        <span
                          class="badge badge-danger"
                          v-else-if="ticket.ra_status.name == 'Não respondido'"
                          >Não respondido</span
                        >
                      </td>
                      <td>
                        <router-link :to="`view-ticket/${ticket.id}`">
                          <i class="zmdi zmdi-comments zmdi-hc-fw"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else-if="fetched" class="text-center table-placeholder">
                  <i class="fas fa-life-ring font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('ticket-component.none')}}</h5>
                  <p>
                    <a
                      href="/support/new-ticket"
                      class="btn btn-success"
                      aria-expanded="false"
                    >
                      {{$t('generic-str.lbl-open-ticket')}}
                    </a>
                  </p>
                </div>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from '@/services/ticket.service';
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
import RAService from '@/services/ra.service';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      tickets: [],
      answered: 0,
      unAnswered: 0,
      resolved: 0,
      resolvedPercent: 0,
      answeredPercent: 0,
      form: {
        id: '',
        status: '',
        sort: 'created_at:desc',
        page: 1,
      },
      pages: 1,
      exporting: false,
      isSending: false,
      opens: [],
      opensPercent: 0,
      total: 0,
    };
  },
  computed: {
    info() {
      return this.$store.state.account;
    },
  },
  created() {
    // this.fetch(1);
    this.getTickets();
  },
  methods: {
    getTickets() {
      RAService.getTickets().then(
        (response) => {
          console.log('Tickets');
          console.log(response);
          this.tickets = response.data;
          this.fetched = true;
          response.data.forEach((item) => {
            if (item.ra_status.name === 'Avaliado Resolvido') {
              this.resolved += 1;
            }
            if (item.ra_status.name === 'Respondido') {
              this.answered += 1;
            }
            if (item.ra_status.name === 'Não respondido') {
              this.unAnswered += 1;
            }
          });
          this.total += this.unAnswered + this.answered + this.resolved;
          this.answeredPercent = this.answered > 0 ? (this.answered / this.total) * 100 : 0;
          this.unAnsweredPercent = this.unAnswered > 0 ? (this.unAnswered / this.total) * 100 : 0;
          this.resolvedPercent = this.resolved > 0 ? (this.resolved / this.total) * 100 : 0;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      TicketService.getTickets(this.form).then(
        (response) => {
          this.fetched = true;
          this.tickets = response.data;
          this.opens = [];
          this.answered = [];
          // this.pages = response.last_page;
          if (this.tickets.length > 0) {
            this.tickets.forEach((element) => {
              if (element.status === 'open') {
                this.opens.push(element);
              }

              if (element.status === 'answered') {
                this.answered.push(element);
              }
            });
            this.opensPercent =
              (this.opens.length / this.tickets.length) * 100;
            this.answeredPercent =
              (this.answered.length / this.tickets.length) * 100;
          }
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
td a i {
  font-size: 1.19rem;
}
</style>
